<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="电站名称">
      <div v-if="operateType === 'single'">
        {{ currentRow.stationName }}
      </div>
      <el-scrollbar v-else-if="operateType === 'multiple'" :viewStyle="{ maxHeight: '200px' }">
        <div v-for="item in selectedRows" style="margin: 4px 0" :key="item.stationId">
          {{ item.stationName }}
        </div>
      </el-scrollbar>
    </ykc-form-item>
    <ykc-form-item label="是否收取抽成" prop="collectFlag">
      <ykc-radio :data="collectFlagData" v-model="ruleForm.collectFlag"></ykc-radio>
    </ykc-form-item>
    <template v-if="ruleForm.collectFlag === '1'">
      <ykc-form-item label="电费抽成" prop="powerRate">
        <div class="_line">
          <ykc-dropdown
            placeholder="请选择"
            :clearable="false"
            :data="typeData"
            :disabled="true"
            v-model="ruleForm.powerRateType"></ykc-dropdown>
          <ykc-input
            v-model="ruleForm.powerRate"
            type="number"
            :disabled="true"
            :placeholder="`请输入${ruleForm.powerRateType === '2' ? '折扣' : '单价'}`">
            <template slot="append">
              {{ appendRateType(ruleForm.powerRateType) }}
            </template>
          </ykc-input>
        </div>
      </ykc-form-item>
      <ykc-form-item label="服务费抽成" prop="serviceRate">
        <div class="_line">
          <ykc-dropdown
            placeholder="请选择"
            :clearable="false"
            :data="typeData"
            v-model="ruleForm.serviceRateType"></ykc-dropdown>
          <ykc-input
            v-model="ruleForm.serviceRate"
            type="number"
            :placeholder="`请输入${ruleForm.serviceRateType === '2' ? '折扣' : '单价'}`">
            <template slot="append">
              {{ appendRateType(ruleForm.serviceRateType) }}
            </template>
          </ykc-input>
        </div>
      </ykc-form-item>
    </template>
  </ykc-form>
</template>

<script>
  import { commissionStrategy } from '@/service/apis';

  export default {
    props: ['selectedRows', 'currentRow', 'operateType'],
    data() {
      return {
        collectFlagData: [
          {
            id: '0',
            name: '否',
          },
          {
            id: '1',
            name: '是',
          },
        ],
        typeData: [
          {
            id: '1',
            name: '度数',
          },
          {
            id: '2',
            name: '折扣',
          },
          {
            id: '3',
            name: '小时',
          },
          {
            id: '4',
            name: '次数',
          },
        ],
        ruleForm: {
          collectFlag: '0',
          powerRateType: '1',
          powerRate: '',
          serviceRateType: '1',
          serviceRate: '',
        },
        rules: {
          collectFlag: [{ required: true, message: '请选择是否收取抽成', trigger: 'blur' }],
          powerRate: [
            { required: false, message: '请输入电费抽成', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (this.ruleForm.powerRateType === '2') {
                  if (value < 0 || value > 100) {
                    return cb(new Error('折扣比例范围为0-100'));
                  }
                  return cb();
                }
                if (this.ruleForm.powerRateType === '1') {
                  if (val.replace(/.*\.(.*)$/, '$1').length > 4) {
                    cb(new Error('最多四位小数'));
                  }
                  if (value < 0 || value > 9.9999) {
                    return cb(new Error('单价范围为0-9.9999'));
                  }
                  return cb();
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
          serviceRate: [
            { required: true, message: '请输入服务费抽成', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (this.ruleForm.serviceRateType === '2') {
                  if (value < 0 || value > 100) {
                    return cb(new Error('折扣比例范围为0-100'));
                  }
                  return cb();
                }
                if (this.ruleForm.serviceRateType === '1') {
                  if (val.replace(/.*\.(.*)$/, '$1').length > 4) {
                    cb(new Error('最多四位小数'));
                  }
                  if (value < 0 || value > 9.9999) {
                    return cb(new Error('单价范围为0-9.9999'));
                  }
                  return cb();
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
        },
      };
    },
    computed: {},
    created() {
      if (this.operateType === 'single') {
        this.ruleForm.collectFlag = this.currentRow.collectFlag || this.ruleForm.collectFlag;
        this.ruleForm.powerRateType = this.currentRow.powerRateType || this.ruleForm.powerRateType;
        this.ruleForm.powerRate = this.currentRow.powerRate || this.ruleForm.powerRate;
        this.ruleForm.serviceRateType =
          this.currentRow.serviceRateType || this.ruleForm.serviceRateType;
        this.ruleForm.serviceRate = this.currentRow.serviceRate || this.ruleForm.serviceRate;
      }
    },
    methods: {
      appendRateType(type) {
        switch (type) {
          case '1':
            return '元/度';
          case '2':
            return '%';
          case '3':
            return '元/时';
          case '4':
            return '元/次';
          default:
            return '元/度';
        }
      },
      submitForm(callback) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.operateType === 'single') {
              const data = {
                ...this.ruleForm,
                stationId: this.currentRow.stationId,
              };
              commissionStrategy.edit(data).then(res => {
                console.log('commissionStrategy single edit', res);
                callback();
              });
            } else if (this.operateType === 'multiple') {
              const data = {
                ...this.ruleForm,
                stationIds: this.selectedRows.map(({ stationId }) => stationId),
              };
              commissionStrategy.batchEdit(data).then(res => {
                console.log('commissionStrategy multiple edit', res);
                callback();
              });
            }
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
